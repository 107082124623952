'use strict';

import Alpine from 'alpinejs';
import axios from 'axios';

const api = axios.create({
    baseURL: '/install/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

const licenseApiBaseUrl = "https://api.aikeedo.com/licenses";

export function installerView() {
    Alpine.data('installer', () => ({
        step: 'welcome',
        model: {
            license: null,
            db: {
                driver: 'pdo_mysql',
                host: 'localhost',
                port: 3360,
                name: 'aikeedo',
                user: null,
                password: null,
            },
            account: {
                first_name: null,
                last_name: null,
                email: null,
                password: null
            },
        },
        isProcessing: false,
        requirements: null,
        isSubmitable: false,
        form: null,
        required: [],
        error: null,

        init() {
            this.$refs.loading.remove();

            this.checkIsSubmitable();
        },

        checkIsSubmitable() {
            for (let i = 0; i < this.required.length; i++) {
                const el = this.required[i];

                if (!el.value) {
                    this.isSubmitable = false;
                    return;
                }
            }

            this.isSubmitable = true;
        },

        view(step) {
            this.step = step;
            this.isSubmitable = false;

            setTimeout(() => {
                this.form = document.querySelector('form');
                this.required = [];

                if (this.form) {
                    this.form.querySelectorAll('[required]').forEach((element) => {
                        this.required.push(element);
                        element.addEventListener('input', () => this.checkIsSubmitable());
                    });
                }

                if (step != 'requirements') {
                    this.checkIsSubmitable();
                }
            }, 1000);
        },

        viewRequirement() {
            this.isProcessing = true;

            api.post('/requirements')
                .then((resp) => {
                    this.step = 'requirements';
                    this.isProcessing = false;
                    this.requirements = resp.data;

                    this.view('requirements');
                    this.isSubmitable = resp.data.is_satisfied;
                });
        },

        submitLicenseForm() {
            this.isProcessing = true;

            // Check license here
            axios.get(`${licenseApiBaseUrl}/${this.model.license}`)
                .then(resp => {
                    this.isProcessing = false;
                    this.error = null;
                    this.view('db');
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.error = error.response.data.message || 'Invalid license key';
                });
        },

        submitDbForm() {
            this.isProcessing = true;

            api.post('/db', this.model.db)
                .then(resp => {
                    this.isProcessing = false;
                    this.error = null;
                    this.view('account');
                })
                .catch(error => {
                    this.isProcessing = false;
                    this.error = error.response.data.message || null;
                });
        },

        async submitAccountForm() {
            this.isProcessing = true;

            try {
                await api.post('/install', this.model)
            } catch (error) {
                this.isProcessing = false;
                this.error = error.response.data.message || null;
                this.view('failure');
                return;
            }

            this.error = null;

            let ip = null;
            try {
                let resp = await axios.get('http://ip-api.com/json/');
                ip = resp.data.query;
            } catch (error) {
            }

            // Activate license here
            try {
                await axios.post(`${licenseApiBaseUrl}/${this.model.license}/activate`, {
                    'license': this.model.license,
                    'domain': window.location.hostname,
                    'ip': ip,
                    'email': this.model.account.email,
                    'first_name': this.model.account.first_name,
                    'last_name': this.model.account.last_name,
                    'version': ''
                });
            } catch (error) {
            }

            this.isProcessing = false;
            this.view('success');
        }
    }))
}